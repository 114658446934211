.my-line-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* 限制在三行 */
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-input-number {
  width: 200px !important;
}
.ant-input-number-outlined {
  width: 200px !important;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-20 {
  margin-left: 20px;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-l-18 {
  padding-left: 18px !important;
}
.ant-dropdown-menu-title-content {
  display: flex;
}
td,
th {
  vertical-align: middle !important;
  white-space: nowrap;
}
/* th {
  text-align: center !important;
} */
.ant-steps-item-description {
  min-width: 100px;
  white-space: nowrap !important;
}
/* .ant-table-column-title {
  text-align: center;
} */

.ant-steps-item-content {
  min-width: 80px;
}
.custom-placeholder .ant-select-selection-placeholder {
  color: #000;
}
/* 重置按钮样式 */
.resetstyle {
  color: #1677ff;
  cursor: pointer;
}
/* menu超出隐藏 */
.ant-layout-sider {
  overflow: visible !important;
}
/* 一级菜单的样式 */
/* .ant-menu-submenu-title {
  .ant-menu-title-content {
    font-weight: 600;
  }
} */

/* .ant-tabs-nav::before {
  display: none;
} */
#webpack-dev-server-client-overlay {
  display: none !important;
}
/* 刷新图标 */
.refreshdate {
  width: 50px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}
/* 表头总计样式 */
.table_title_count {
  height: 20px;
  color: #666;
  font-size: 12px;
}
